$maincolor: #222222;
$graycolor: #6E787F;
$transwhite: rgba(255,255,255,0.8);
$borderinput: #dbdbdb;
$borderdark: 1px solid $maincolor;
$borderlight: 1px solid $borderinput;
$topmargin: 145px; 
$pagemenumargin: 145px; 
$break-tablet: 992px;
$break-phone: 600px;
$break-phone-small: 320px;
$smalltext: 0.9em;
$normaltext: 1em;
$mediumtext: 1.2em;
$bigtext: 1.4em;
$titletext: 36px;
$hugetext: 36px;
$lineheight: 22px;
$halfline: $lineheight/2;
$avenir: "Avenir", Helvetica, Arial, sans-serif; 
$helvetic: Helvetica, Arial, sans-serif; 
$sociconsize: 46px;

