/* CSS Document */

/*
 Global elements
*/
/* Reset all elements */

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, select, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0; }

table {
	border-collapse: collapse;
	border-spacing: 0px; }

th, td {
	vertical-align: top; }

fieldset, img {
	border: 0; }

address, caption, cite, code, dfn, th, var {
	font-style: normal; }

ol, ul {
	list-style: none; }

caption, th {
	text-align: left; }

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	text-align: left; }

b, strong {
	font-weight: bold; }

:active {
	outline: medium none; }

:focus {
	outline: medium none; }

html, body {
		margin: 0;
}

@font-face {
	font-family: "Avenir";
	src: url("/static/fonts/avenir/Avenir.ttf"); }