/* tablet.css */

@import "var";

$topmargin: 49px;
$pagemenumargin: 97px;
$sociconsize: 52px;

@media only screen and (max-width: $break-tablet) {

.collapse { display: none; }

#header{
	margin-top: 0!important;
	#logodiv{
		height: 48px;
		line-height: 48px;
		font-size: 24px;
		overflow: hidden;
		.holder{
			padding-top: 0;
			padding-bottom: 0;
			.logo {
				width: 34px;
				height:44px;
				line-height: 14px;
				font-size: 16px;
				overflow: hidden;
				border:none;
				margin-right: 2%;
				padding-top:4px;
				word-wrap: break-word;
				img { width: 100%; }
			}
			h1{
				float: none;
				text-align:center;
				line-height: 48px;
			}
		}
		.navbar-toggle {
			display: block;
			position: relative;
		    float: right;
		    background: none;
		    padding: 15px 10px;
		    border-bottom:none;
		    box-sizing:border-box;
		    max-height:48px;
		    overflow: hidden;
			.icon-bar {
			    display: block;
			    position: relative;
			    width: 22px;
			    height: 3px;
			    margin-bottom: 3px;
			    background-color: $maincolor;
			}
		}
	}
}

#topmenumob {
	position:fixed;
	right:5%;
	top: 56px;
	max-height: calc(100vh - 56px);
	min-width:250px;
	overflow-y:auto;
	margin-top:0;
	font-size: $bigtext;
	font-weight: bold;
	background:white;
	border: 1px solid $borderinput;
	z-index: 1002;
	ul.top-nav {
		li {
			text-align:center;
			text-transform: lowercase;
			display: block;
			margin:0;
			a {
				display: block;
				padding: 10px 0;
				border-top: 1px solid transparent;
				border-bottom: 1px solid transparent;
				&:hover {
					border-top: 1px solid $borderinput;
					border-bottom: 1px solid $borderinput;
				}
			}
			i.material-icons {
				position:absolute;
				right:4px;
				padding: 10px;
				cursor:pointer;
				&:hover { color: #888;}
			}
			ul {
				display: none;
				li {
					font-size: 80%;
					a {
						padding-left:0;
						border-top: 1px solid transparent;
						border-bottom: 1px solid transparent;
						color:#444;
					}
				}
			}
		}
		li.activeitem {
			a {
				border-top: 1px solid $borderinput;
				border-bottom: 1px solid $borderinput;
			}
			ul li a {
				border-color: transparent!important;
			}
		}
		li.active {
			ul {
				display: inherit;
			}
		}
	}
	#cartlangs {
		float:none;
		padding: 0;
		position:relative;
		#cart {
			float: none;
			a {
				display: block;
				padding: 10px 0;
				text-align:center;
				border-top: 1px solid transparent;
				border-bottom: 1px solid transparent;
				&:hover {
					border-top: 1px solid $borderinput;
					border-bottom: 1px solid $borderinput;
				}
			}
		}
		#l10n {
			position:absolute;
			right:10px;
			top:12px;
			margin-right:0px;
			z-index: 2;
		}
	}
}

.topmargin {
	margin-top: $topmargin;
}
.pagemenumargin {
	margin-top: $pagemenumargin;
}

/*Grid*/


.third, .quarter { width: 50%; }

.tile {
	&.third {
		&:nth-of-type(3n+1), &:nth-of-type(3n+2), &:nth-of-type(3n+3) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	&.quarter {
		&:nth-of-type(4n+1), &:nth-of-type(4n+2), &:nth-of-type(4n+3), &:nth-of-type(4n+4) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	&.third, &.quarter {
		width: 48%;
		&:nth-of-type(2n+1) { margin-right:2%; }
		&:nth-of-type(2n) { margin-left:2%; }
	}
}

.cols2 { column-count: 2; -webkit-column-count: 2; -moz-column-count: 2; }
.cols3 { column-count: 2; -webkit-column-count: 2; -moz-column-count: 2; }
.cols4 { column-count: 2; -webkit-column-count: 2; -moz-column-count: 2; }

/*Shop*/

#pageMenu {
	position: fixed;
	top:49px;
}

.pageMenu {
 	ul li {
		font-size: $smalltext;
	}
}

/*Shop*/

.index {
	.product-tile:nth-last-of-type(3n) { display:none; }
}

/*Product*/

.product {
	.left-col {
		width: 78%;
	}
	.right-col {
		width: 20%;
		.materials{
			.col{
				width: 100%;
				float: none;
			}
		}
		.dif-circle{
			width: 6px;
			height: 6px;
			margin-right: 4px;
			margin-top: 2px;
		}
	&.setups, &.price-col { width:50%; }
	&.expand, &.comments, &.desc {width:100%!important; } 
	}
	.quote {
		padding: 2*$lineheight 0;
		font-size: $bigtext; 
	}
}

/*Slider*/

.slider {
	margin-bottom: 2*$lineheight;
	.desc{
		line-height:1.6;
		font-weight:normal;
		h1, h2, h3 {
			font-family: $avenir;
			font-weight: bold;
			line-height:1.6;
		}

	}
	.swiper-container-slider, .swiper-container-single {
		padding-bottom: $lineheight;
		.swiper-slide {
			.desc {
				font-size: $normaltext;
				h1 { font-size: $bigtext; }
				h2 { font-size: $mediumtext; }
				h3 { font-size: $normaltext; }
				p { font-size: $normaltext; }

			 }
			.call-to-action{
				font-size: $normaltext;
			}
			.call-to-action-holder {
				top:20%;
			}
		}
	}
}

.carousel {
    .swiper-container-carousel {
	    .swiper-slide {
	    	width: 48%;
	    }

    }
}

.swiper-button-prev, .swiper-button-next { display:none!important; }
.swiper-pagination { bottom:0!important; }

/*#page-cart{
	.quarter {
		width: 23%;
		&.tile:nth-of-type(4n+1) { margin-right:2%; }
		&.tile:nth-of-type(4n+2) { margin-left: 1%; margin-right: 1%; }
		&.tile:nth-of-type(4n+3) { margin-left: 1%; margin-right: 1%; }
		&.tile:nth-of-type(4n+4) { margin-left: 2%; }
	}
}*/

footer{
	padding: 30px 0;
	h3{
		font-size: $bigtext;
		margin-bottom: 10px;
	}
	.third.tile {
		width: 31%;
		&.tile:nth-of-type(2n+1) { margin-right:0; }
		&.tile:nth-of-type(2n) { margin-left:0; }
		&.tile:nth-of-type(3n+1) { margin-right:2%; }
		&.tile:nth-of-type(3n+2) { margin-right:1%; margin-left:1%; }
		&.tile:nth-of-type(3n+3) { margin-left:2%; }
	}
}

/*Social*/

a.socicon-button {
	width: $sociconsize;
	height: $sociconsize;
	font-size: 0.4*$sociconsize;
	line-height: $sociconsize;
	&:before {
		line-height: $sociconsize!important;
	}
}

#cookie-consent {
	.consent-container {
		display:block;
		.consent-text {
			width:100%;
			text-align:center;
		}
		.btns {
			width:100%;
			text-align:center;
			min-width: inherit!important;
			margin-top: 0.5em;
		}
	}
	a.close {
		display: none;
	}
}

}

@media only screen and (max-width: 640px) and (min-width: 601px) {
.pageMenu {
 	ul li {
		font-size: 13px;
	}
}}
