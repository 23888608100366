/* main.css */
body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 22px;
  font-weight: 600;
  background: #fff;
  color: #222222;
  overflow-x: hidden; }

h1, h2, h3, h4 {
  font-family: "Avenir", Helvetica, Arial, sans-serif; }

h1 {
  line-height: 1; }

h2, h3, h4 {
  line-height: 1.6; }

h2 {
  font-size: 36px; }

h3 {
  font-size: 1.4em; }

h4 {
  font-size: 1em;
  font-style: italic; }

hr {
  border-top: 0px solid #fff;
  border-bottom: 1px solid #222222;
  margin: 0px;
  height: 0px; }

/*Links*/
a, a:visited {
  color: #222222;
  text-decoration: none; }

a:hover {
  color: #222222;
  text-decoration: none;
  border-bottom: 1px solid #222222; }

a.no-border, a.logo, .slideCall a, a.buy, a.close {
  border-bottom: none !important; }

a.callLink {
  color: red; }
  a.callLink:hover {
    border-bottom: 1px solid red; }

a.call-btn, button.call-btn {
  display: inline-block;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1em;
  background: red;
  color: #fff;
  padding: 0.5em 1em;
  border: none !important;
  box-sizing: border-box; }
  a.call-btn:hover, button.call-btn:hover {
    opacity: 0.7;
    background-color: #f22; }

.fakelink {
  cursor: pointer; }

.close {
  float: right; }

a.delete {
  color: red !important; }
  a.delete:hover {
    border-bottom: none; }

a[ng-click] {
  cursor: pointer; }

.delbutton {
  background-color: red; }

/*Align*/
.wrapper {
  width: 100%; }

.wrapper.main {
  min-height: calc(100vh - 336px);
  margin-bottom: 66px; }
  .wrapper.main a.nounderline {
    border-bottom: none; }

.container {
  position: relative;
  width: 1000px;
  margin: auto; }

.topmargin {
  margin-top: 145px; }

.pagemenumargin {
  margin-top: 145px; }

.half {
  width: 50%; }

.third {
  width: 33.33%; }

.quarter {
  width: 25%; }

.two-thirds {
  width: 66.66%; }

.one-third {
  width: 33.33%; }

.tile, .product .body .half, .product .body .third, .post .body .half, .post .body .third {
  float: left;
  box-sizing: border-box; }
  .tile.half, .product .body .half, .post .body .half {
    width: 48%; }
    .tile.half:nth-of-type(2n+1), .product .body .half:nth-of-type(2n+1), .post .body .half:nth-of-type(2n+1) {
      margin-right: 2%; }
    .tile.half:nth-of-type(2n+2), .product .body .half:nth-of-type(2n+2), .post .body .half:nth-of-type(2n+2) {
      margin-left: 2%; }
  .tile.third, .product .body .third, .post .body .third {
    width: 31%; }
    .tile.third:nth-of-type(3n+1), .product .body .third:nth-of-type(3n+1), .post .body .third:nth-of-type(3n+1) {
      margin-right: 2%; }
    .tile.third:nth-of-type(3n+2), .product .body .third:nth-of-type(3n+2), .post .body .third:nth-of-type(3n+2) {
      margin-right: 1%;
      margin-left: 1%; }
    .tile.third:nth-of-type(3n+3), .product .body .third:nth-of-type(3n+3), .post .body .third:nth-of-type(3n+3) {
      margin-left: 2%; }
  .tile.quarter, .product .body .quarter.half, .product .body .quarter.third, .post .body .quarter.half, .post .body .quarter.third {
    width: 23%; }
    .tile.quarter:nth-of-type(4n+1), .product .body .quarter.half:nth-of-type(4n+1), .product .body .quarter.third:nth-of-type(4n+1), .post .body .quarter.half:nth-of-type(4n+1), .post .body .quarter.third:nth-of-type(4n+1) {
      margin-right: 2%; }
    .tile.quarter:nth-of-type(4n+2), .product .body .quarter.half:nth-of-type(4n+2), .product .body .quarter.third:nth-of-type(4n+2), .post .body .quarter.half:nth-of-type(4n+2), .post .body .quarter.third:nth-of-type(4n+2) {
      margin-left: 1%;
      margin-right: 1%; }
    .tile.quarter:nth-of-type(4n+3), .product .body .quarter.half:nth-of-type(4n+3), .product .body .quarter.third:nth-of-type(4n+3), .post .body .quarter.half:nth-of-type(4n+3), .post .body .quarter.third:nth-of-type(4n+3) {
      margin-left: 1%;
      margin-right: 1%; }
    .tile.quarter:nth-of-type(4n+4), .product .body .quarter.half:nth-of-type(4n+4), .product .body .quarter.third:nth-of-type(4n+4), .post .body .quarter.half:nth-of-type(4n+4), .post .body .quarter.third:nth-of-type(4n+4) {
      margin-left: 2%; }
  .tile.two-thirds, .product .body .two-thirds.half, .product .body .two-thirds.third, .post .body .two-thirds.half, .post .body .two-thirds.third {
    width: 64%;
    margin-right: 2%; }
  .tile.one-third, .product .body .one-third.half, .product .body .one-third.third, .post .body .one-third.half, .post .body .one-third.third {
    width: 32%;
    margin-left: 2%; }

.third.center, .quarter.center {
  text-align: center; }

.half.right, .third.right, .quarter.right {
  text-align: right;
  float: right; }

.flex-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .flex-wrapper .flex-items {
    flex: 1; }

.flex-items {
  display: flex; }
  .flex-items .flex-item {
    box-sizing: border-box;
    flex: 1; }

.block {
  width: 100%;
  margin-bottom: 44px; }

.block-big {
  width: 100%;
  margin-bottom: 88px; }

.block.hr {
  padding-bottom: 44px; }

.block-small {
  width: 100%;
  margin-bottom: 22px; }

.fullwidth {
  width: 100%; }

.per90 {
  width: 90%; }

.vertical-half-space {
  height: 11px; }

.table td {
  padding: 4px 20px 4px 0px; }

.dotted tr {
  border-bottom: 1px dotted #6E787F; }

.table-even {
  width: 100%;
  table-layout: fixed; }

.table-even input[type="text"] {
  width: 95%; }

.cols2 {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2; }

.cols3 {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3; }

.cols4 {
  column-count: 4;
  -webkit-column-count: 4;
  -moz-column-count: 4; }

.cols2,
.cols3,
.cols4 {
  -webkit-column-gap: 30px;
  /* Chrome, Safari, Opera */
  -moz-column-gap: 30px;
  /* Firefox */
  column-gap: 30px; }

/*Color and text size*/
.smalltext {
  font-size: 0.9em; }

.normaltext {
  font-size: 1em; }

.mediumtext {
  font-size: 1.2em; }

.bigtext {
  font-size: 1.4em; }

.titletext {
  font-size: 36px; }

.transpw {
  background: rgba(255, 255, 255, 0.8); }

.message {
  margin: 22px 0px; }

#error-message, .flashes, .discounted {
  color: red; }

.flashes {
  width: 100%;
  margin-bottom: 44px; }
  .flashes a {
    color: red; }
    .flashes a:hover {
      border-color: red; }

/*Common*/
.capitalize {
  text-transform: capitalize; }

.smaller {
  font-size: 85%; }

.italic {
  font-style: italic; }

.pointer {
  cursor: pointer; }

.fright {
  float: right; }

.fleft {
  float: left; }

.rel {
  position: relative; }

.abs {
  position: absolute; }

.fixed {
  position: fixed; }

.mosttop {
  position: absolute;
  z-index: 9999999; }

.hidden {
  display: none; }

.visible {
  overflow: visible !important; }

.padd {
  padding: 1em 2em; }

.no-padding {
  padding: 0 !important; }

.hr {
  border-bottom: 1px solid #222222; }

.clearfix {
  width: 100%;
  clear: both; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.info {
  background-color: white;
  background-repeat: no-repeat;
  background-position: right 10px;
  background-size: auto 130px;
  padding: 5px;
  opacity: 0.8;
  z-index: 2; }

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.8); }
  .loader img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
    display: block; }

.scrolX {
  overflow-x: scroll; }

/*Social*/
.socicons .flex-item {
  margin-bottom: 5%; }

a.socicon-btn {
  display: block;
  width: 46px;
  height: 46px;
  font-size: 18.4px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: white;
  background: #222222;
  border-radius: 100%;
  vertical-align: middle; }
  a.socicon-btn:hover {
    border-bottom: none; }
  a.socicon-btn:before {
    line-height: 46px !important; }
  a.socicon-btn.socicon-facebook:hover {
    background: #3e5b98; }
  a.socicon-btn.socicon-instagram:hover {
    background: #e4405f; }
  a.socicon-btn.socicon-twitter:hover {
    background: #4da7de; }
  a.socicon-btn.socicon-googleplus:hover {
    background: #dd4b39; }
  a.socicon-btn.socicon-pinterest:hover {
    background: #c92619; }
  a.socicon-btn.socicon-linkedin:hover {
    background: #3371b7; }
  a.socicon-btn.socicon-youtube:hover {
    background: #e02a20; }

.social-btns a.socicon-btn {
  display: inline-block;
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin: 0 11px 6px 0;
  font-size: 21px;
  line-height: 42px; }
  .social-btns a.socicon-btn:before {
    line-height: 42px !important; }
  .social-btns a.socicon-btn.socicon-facebook {
    background: #3e5b98; }
  .social-btns a.socicon-btn.socicon-instagram {
    background: #e4405f; }
  .social-btns a.socicon-btn.socicon-twitter {
    background: #4da7de; }
  .social-btns a.socicon-btn.socicon-googleplus {
    background: #dd4b39; }
  .social-btns a.socicon-btn.socicon-pinterest {
    background: #c92619; }
  .social-btns a.socicon-btn.socicon-linkedin {
    background: #3371b7; }
  .social-btns a.socicon-btn.socicon-youtube {
    background: #e02a20; }
  .social-btns a.socicon-btn:hover {
    background: #222222;
    cursor: pointer; }

/*Comments*/
.comment {
  position: relative;
  margin-bottom: 11px; }
  .comment b {
    font-weight: bold; }
  .comment p {
    font-weight: normal; }
    .comment p img {
      max-height: 16px; }
  .comment input[type="text"], .comment textarea {
    width: 100%; }
    .comment input[type="text"]:required, .comment input[type="text"]:invalid, .comment textarea:required, .comment textarea:invalid {
      box-shadow: none; }

.comment.hr {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 11px; }

.comment.hr:last-child {
  border-bottom: none;
  padding-bottom: 0px; }

/*Header*/
#header {
  padding-bottom: 0;
  width: 100%;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  transition: margin .2s ease-out; }
  #header #topmenu {
    font-size: 16px;
    padding: 8px 0px;
    font-weight: bold;
    margin-top: 2px; }
    #header #topmenu ul li {
      text-transform: lowercase;
      display: inline;
      margin-right: 32px; }
      #header #topmenu ul li.active a {
        border-bottom: 1px solid #222222; }
      #header #topmenu ul li ul {
        display: none; }
    #header #topmenu ul.top-nav li i.material-icons {
      display: none; }
    #header #topmenu #cartlangs {
      float: right; }
      #header #topmenu #cartlangs #cart {
        float: right;
        margin-left: 20px; }
      #header #topmenu #cartlangs #l10n {
        float: right;
        position: relative; }
        #header #topmenu #cartlangs #l10n div.current {
          line-height: 1px; }
        #header #topmenu #cartlangs #l10n ul {
          position: absolute;
          top: 22px;
          left: 0px; }
          #header #topmenu #cartlangs #l10n ul li {
            display: block;
            margin-right: 0;
            line-height: 1px; }
            #header #topmenu #cartlangs #l10n ul li img {
              margin-top: 0px; }
            #header #topmenu #cartlangs #l10n ul li a {
              margin: 0;
              display: block;
              padding: 1px 0px; }
              #header #topmenu #cartlangs #l10n ul li a:hover {
                border: none; }
          #header #topmenu #cartlangs #l10n ul.ng-hide-remove {
            opacity: 0; }
          #header #topmenu #cartlangs #l10n ul.ng-hide-remove-active {
            opacity: 1; }
          #header #topmenu #cartlangs #l10n ul.ng-hide-add {
            opacity: 1;
            transition: all .5s; }
          #header #topmenu #cartlangs #l10n ul.ng-hide-add-active {
            opacity: 0; }
  #header #logodiv {
    width: 100%;
    height: 59px;
    line-height: 52px;
    font-size: 40px;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    color: #222222;
    border-bottom: 1px solid #222222;
    transition: border-color 0.5s linear;
    overflow: hidden; }
    #header #logodiv .holder {
      padding-top: 4px;
      padding-bottom: 2px;
      transition: margin .2s ease-out; }
      #header #logodiv .holder .logo {
        display: block;
        float: left;
        line-height: 52px; }
      #header #logodiv .holder h1 {
        float: right;
        line-height: 52px; }
    #header #logodiv .navbar-toggle {
      display: none; }
  #header.no-border #logodiv {
    border-bottom: 1px solid rgba(0, 0, 0, 0); }

.dropdown li ul {
  display: none;
  position: absolute;
  z-index: 2;
  padding-left: 12px;
  line-height: 19px;
  font-size: 16px; }

.dropdown:hover li ul {
  display: block; }

/*Content*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/*Home*/
.index .products {
  margin-bottom: 88px !important; }

/*Tag*/
.shopBanner {
  padding-bottom: 44px; }

.shopBanner img {
  display: block;
  width: 100%; }

.taginfo {
  margin: 0px 0px 44px 0px; }

section.collection {
  padding-bottom: 44px; }

.products {
  margin-bottom: 44px; }

/*Shop*/
.shop-block .shop-title {
  text-transform: uppercase;
  padding-top: 100px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 44px;
  border-bottom: 1px solid #dbdbdb; }

.shop-block:first-of-type .shop-title {
  padding-top: 0; }

.currency {
  font-family: Helvetica, Arial, sans-serif;
  margin: 0 0.25em;
  font-size: 0.8em;
  line-height: 1em; }

.product-tile {
  overflow: hidden;
  margin-bottom: 44px; }
  .product-tile .desc {
    height: 116px;
    overflow: hidden;
    text-align: center; }
    .product-tile .desc h1 {
      font-size: 1.4em;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px; }
    .product-tile .desc .props {
      height: 22px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 5px;
      overflow: hidden; }
    .product-tile .desc .price-buy {
      width: auto;
      display: inline-block; }
    .product-tile .desc .price-holder, .product-tile .desc .buy, .product-tile .desc .discount {
      float: left;
      position: relative;
      width: 80px;
      height: 34px;
      padding: 6px 0;
      font-size: 1.4em;
      line-height: 1;
      text-align: center;
      font-weight: bold;
      box-sizing: border-box;
      overflow: hidden; }
    .product-tile .desc .price-holder {
      background: #e3e3e3; }
      .product-tile .desc .price-holder.discounted {
        color: red; }
    .product-tile .desc .discount {
      color: red;
      background: white; }
    .product-tile .desc .buy {
      color: #fff;
      text-transform: uppercase;
      font-size: 1.2em;
      padding: 8px 0;
      background: red;
      margin-left: 1px;
      -moz-transition: background 0.2s;
      -o-transition: background 0.2s;
      -webkit-transition: background 0.2s;
      transition: background 0.2s;
      cursor: pointer; }
    .product-tile .desc .buy:hover {
      background: #222222; }
  .product-tile .thumbdiv {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    position: relative;
    overflow: hidden;
    text-align: left; }
    .product-tile .thumbdiv a {
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      border-bottom: none;
      text-align: center; }
    .product-tile .thumbdiv img {
      height: 100%; }

/* Product */
.product h2 {
  margin-bottom: 22px; }

.product h3 {
  margin-bottom: 11px; }

.product .left-col {
  width: 60%;
  float: left; }

.product .right-col {
  width: 38%;
  float: right;
  margin-bottom: 22px; }

.product .body {
  margin-top: 44px; }
  .product .body p {
    padding-bottom: 22px; }
  .product .body .half, .product .body .third {
    margin-bottom: 22px; }
  .product .body img {
    width: 100%; }
  .product .body a {
    border-bottom: 1px solid #222222; }
    .product .body a:hover {
      border-bottom: none; }

.product .specs {
  margin-top: 6px; }
  .product .specs td {
    padding: 4px 10px 4px 0px; }
    .product .specs td:last-child {
      padding-right: 0; }

.product .quote {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  font-size: 36px;
  line-height: 1.6;
  text-align: left;
  padding: 66px 0;
  margin: 88px 0;
  background: #efefef; }
  .product .quote a {
    border: none; }
  .product .quote .quote-container {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-around; }
  .product .quote .quote-text {
    width: 80%;
    text-align: center; }
  .product .quote .quote-author {
    max-width: 12%;
    font-style: normal;
    text-align: right; }
  .product .quote .quote-img {
    max-width: 12%;
    margin-top: 11px; }
    .product .quote .quote-img img {
      width: 100%; }

.setup-links .setup-link {
  display: block;
  float: left;
  width: 22px;
  height: 22px;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  border-top: 1px solid #222222;
  border-right: 0px solid #222222;
  border-bottom: 1px solid #222222;
  border-left: 1px solid #222222; }
  .setup-links .setup-link.current {
    background: #222222;
    color: #fff; }
  .setup-links .setup-link:last-child {
    border-right: 1px solid #222222; }

.setup-link:hover {
  background: #222222;
  color: #fff; }

#pricediv {
  text-align: left;
  width: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif; }
  #pricediv .addtocart, #pricediv .discount {
    float: right;
    text-transform: uppercase;
    width: 50%;
    min-width: 140px;
    text-align: center;
    border: none;
    line-height: 44px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  #pricediv .addtocart {
    font-size: 1.2em;
    background: red;
    color: white; }
  #pricediv .discount {
    color: red;
    font-size: 32px; }
  #pricediv .price-holder {
    float: left;
    margin-bottom: 22px; }
    #pricediv .price-holder .price {
      position: relative;
      float: left;
      line-height: 44px;
      font-size: 36px; }
      #pricediv .price-holder .price .new-price {
        color: red; }
      #pricediv .price-holder .price.big-price {
        font-size: 32px; }

svg.strike-red {
  position: absolute;
  top: 0;
  left: 0; }
  svg.strike-red line {
    stroke: rgba(255, 0, 0, 0.6);
    stroke-width: 4; }

.materials .col {
  width: 50%;
  float: left; }

.materials .col-full {
  width: 100%;
  float: left; }

.materials .item {
  width: 100%;
  float: left;
  cursor: pointer;
  margin-bottom: 10px; }
  .materials .item .name {
    height: 30px;
    line-height: 1;
    word-wrap: break-word;
    display: table-cell;
    vertical-align: middle; }
  .materials .item .check {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 10px;
    position: relative; }
    .materials .item .check svg {
      position: absolute;
      top: 7px;
      left: 5px;
      opacity: 0;
      -moz-transition: opacity 0.25s;
      -o-transition: opacity 0.25s;
      -webkit-transition: opacity 0.25s;
      transition: opacity 0.25s; }
      .materials .item .check svg path {
        fill: #fff; }
    .materials .item .check.checked svg {
      opacity: 1; }
  .materials .item .ctrls {
    float: left; }

.ctrls {
  margin-right: 10px; }
  .ctrls .ctrl {
    width: 30px;
    height: 30px;
    background: #222222;
    font-size: 24px;
    line-height: 30px;
    color: white;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    float: left;
    padding: 0;
    cursor: pointer; }
  .ctrls .input {
    height: 30px;
    width: 34px;
    text-align: center;
    background: #fff;
    line-height: 30px;
    border: 2px solid #222222;
    box-sizing: border-box;
    float: left; }

.materials .item.beech .check svg path {
  fill: #fff; }

.materials .item.maple .check svg path {
  fill: #222222; }

.materials .item.glass .check svg path {
  fill: #222222; }

.materials .item.dark .check svg path {
  fill: #fff; }

.dif-circle {
  width: 10px;
  height: 10px;
  border: 2px solid #222222;
  float: left;
  border-radius: 100%;
  margin-right: 4px;
  margin-top: 2px; }

.dif1 .dif-circle:nth-of-type(1) {
  background: #222222; }

.dif2 .dif-circle:nth-of-type(1) {
  background: #222222; }

.dif2 .dif-circle:nth-of-type(2) {
  background: #222222; }

.dif3 .dif-circle:nth-of-type(1) {
  background: #222222; }

.dif3 .dif-circle:nth-of-type(2) {
  background: #222222; }

.dif3 .dif-circle:nth-of-type(3) {
  background: #222222; }

.dif4 .dif-circle {
  background: #222222; }

.dif4 .dif-circle:nth-of-type(5) {
  background: transparent; }

.dif5 .dif-circle {
  background: #222222; }

.stars .star {
  float: left;
  margin-right: 0px;
  cursor: pointer;
  transition: all .2s; }
  .stars .star svg {
    width: 20px;
    height: 20px; }
    .stars .star svg polygon {
      fill: #fff;
      stroke: #222222;
      stroke-width: 14px;
      transition: fill .2s; }
  .stars .star.over svg polygon {
    fill: #222222; }

.stars.rated .star:not(.over) {
  transform: scale(0.8); }

.sprite-container {
  width: 100%;
  padding-bottom: 85%;
  position: relative; }

.sprite-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }

#mySpriteSpin {
  cursor: url("/static/images/rotate.png"), url("/static/images/rotate.png"), auto; }

#mySpriteLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  #mySpriteLoader img {
    max-width: 100%;
    max-height: 100%; }

#rotateTooltip {
  display: none;
  position: absolute;
  bottom: 20%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3; }
  #rotateTooltip img {
    vertical-align: middle;
    margin-right: 10px;
    width: 24px; }

.download .downloads-icon {
  float: left;
  margin-right: 2%;
  width: 25%; }
  .download .downloads-icon img {
    width: 100%; }

.download .material-icons {
  display: block;
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 2%;
  line-height: 32px;
  text-align: center;
  border-radius: 100%;
  background: white;
  color: #222222;
  border: 2px solid #222222;
  box-sizing: border-box; }

.download .title {
  line-height: 32px;
  font-size: 1.2em; }

.download a:hover {
  /*border:none;*/ }
  .download a:hover .material-icons {
    color: white;
    background: #222222; }
  .download a:hover .downloads-icon {
    opacity: 0.7; }

/*# sourceMappingURL=product.css.map */
/*Page*/
.page {
  padding: 44px 0px; }
  .page.with-menu {
    padding-top: 88px; }

#pageMenu {
  width: 100%;
  position: absolute;
  z-index: 10001; }

#pageMenu.stick {
  position: fixed;
  bottom: auto;
  top: 60px; }

.pageMenu ul {
  position: relative;
  white-space: nowrap; }
  .pageMenu ul li {
    text-transform: uppercase;
    display: inline-block;
    margin-right: 3%;
    padding: 11px 0 11px 0; }
    .pageMenu ul li:last-child {
      margin-right: 0; }
    .pageMenu ul li a {
      display: block;
      box-sizing: border-box;
      margin-bottom: 1px; }
      .pageMenu ul li a:hover {
        margin-bottom: 0; }
  .pageMenu ul li.activeitem a {
    border-bottom: 1px solid #222222; }

.post .body .half, .post .body .third {
  margin-bottom: 22px; }

.post .body img {
  max-width: 100%;
  margin-bottom: 22px; }

.post img.fullwidth {
  width: 100% !important;
  margin-bottom: 22px; }

.post img.left50 {
  width: 49% !important;
  float: left;
  margin: 0 2% 22px 0; }

.post img.right50 {
  width: 49% !important;
  float: right;
  margin: 0px 0 22px 2%; }

.post blockquote {
  margin: 11px 22px;
  font-style: italic; }

.post h1 {
  font-size: 36px;
  margin: 11px 0; }

.post h2 {
  font-size: 36px;
  margin-bottom: 22px; }

.post h3 {
  font-size: 1.4em;
  margin-bottom: 22px; }

.post a {
  border-bottom: 1px solid #222222; }
  .post a:hover {
    border-bottom: none; }

.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 28.2%;
  padding-bottom: 28.2%; }
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.blogpost {
  display: block;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 22px; }
  .blogpost h1 {
    font-size: 1.4em;
    border-bottom: 1px solid transparent;
    margin-bottom: 5px;
    cursor: pointer; }
    .blogpost h1 a:hover {
      border-bottom: 1px solid #222222; }
  .blogpost h2 {
    font-size: 16px; }
  .blogpost .blogThumb {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden; }
    .blogpost .blogThumb a {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-bottom: none;
      text-align: center; }
    .blogpost .blogThumb img {
      width: 100%; }
  .blogpost .desc {
    height: 138px;
    padding-top: 11px;
    overflow: hidden; }
  .blogpost.slide .blogThumb {
    padding-bottom: 43%; }
  .blogpost.slide .desc a {
    color: #6E787F; }
  .blogpost.slide .desc.active a {
    color: #222222; }

.quotes.tile, .product .body .quotes.half, .product .body .quotes.third, .post .body .quotes.half, .post .body .quotes.third {
  margin-bottom: 44px; }

.quotes .imgholder {
  position: relative;
  height: 0;
  padding-bottom: 43%;
  margin-bottom: 11px; }
  .quotes .imgholder img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    display: block; }

.quotes .desc {
  height: 132px;
  overflow: hidden;
  font-style: italic;
  font-size: 16px; }

/*Contact*/
.contacts h2 {
  line-height: 46px;
  margin-bottom: 22px; }

.business-card {
  font-size: 120%;
  line-height: 1.6; }
  .business-card h3 {
    font-size: 160%;
    line-height: 1.6; }

/*Footer*/
footer {
  background: #f2f2f2;
  padding: 30px 0; }
  footer h3 {
    font-size: 1.4em;
    line-height: 28px;
    margin-bottom: 10px; }
  footer ul li {
    line-height: 28px; }
  footer .footer-imgs {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    line-height: 1px;
    height: 24px;
    margin: 0 auto 4px auto; }
    footer .footer-imgs a {
      border: none; }
    footer .footer-imgs img {
      max-height: 100%;
      margin: 0 4px 4px 0; }

a.socicon-link {
  display: inline-block;
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin: 0 1.6% 4px 0;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: white;
  background: #222222;
  border-radius: 100%;
  vertical-align: middle; }
  a.socicon-link:hover {
    border-bottom: none;
    cursor: pointer; }
  a.socicon-link:before {
    line-height: 28px !important; }
  a.socicon-link.socicon-facebook:hover {
    background: #3e5b98; }
  a.socicon-link.socicon-instagram:hover {
    background: #e4405f; }
  a.socicon-link.socicon-twitter:hover {
    background: #4da7de; }
  a.socicon-link.socicon-googleplus:hover {
    background: #dd4b39; }
  a.socicon-link.socicon-pinterest:hover {
    background: #c92619; }
  a.socicon-link.socicon-linkedin:hover {
    background: #3371b7; }
  a.socicon-link.socicon-youtube:hover {
    background: #e02a20; }

/*# sourceMappingURL=main.css.map */
/*# sourceMappingURL=index.css.map */
/*Cookie consent*/
#cookie-consent {
  padding: 11px 0;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: white;
  box-sizing: border-box;
  border-top: 1px solid #dbdbdb; }
  #cookie-consent .consent-container {
    display: flex;
    justify-content: space-between;
    padding: 0.5em 2%; }
    #cookie-consent .consent-container .btns {
      min-width: 333px; }
    #cookie-consent .consent-container button, #cookie-consent .consent-container a.btn, #cookie-consent .consent-container a.btn-light, #cookie-consent .consent-container a.btn-dark-small {
      margin-left: 3px; }
  #cookie-consent a.close {
    position: absolute;
    right: 0;
    font-size: 36px;
    color: red;
    display: block;
    padding: 4px 6px; }

/*Forms*/
fieldset {
  border: none; }

label {
  vertical-align: top; }

.inputlink {
  display: inline-block;
  background: #222222;
  border: none !important;
  color: white;
  font-size: 1.4em;
  line-height: 22px;
  padding: 6px 14px;
  margin-right: 14px;
  cursor: pointer; }

.inputlink:hover {
  background: #6E787F; }

.inputlink.delbutton {
  background: red; }

a.inputlink, .inputlink a {
  text-decoration: none;
  color: white; }

input[type="text"], textarea {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 22px;
  font-weight: 600;
  background: #fff;
  color: #222222;
  border: none;
  border-bottom: 1px solid #dbdbdb;
  padding: 6px 0px 6px 15px;
  box-sizing: border-box; }
  input[type="text"].disabled, textarea.disabled {
    background: #dbdbdb; }
  input[type="text"].disabled.enabled, textarea.disabled.enabled {
    background: #fff; }

input[type="number"] {
  width: 50px;
  border: 1px solid #dbdbdb;
  font-weight: bold; }

input.subproducts-amount {
  width: 50px; }

select {
  border: 1px solid #dbdbdb;
  padding: 0px;
  font-weight: bold; }

select.selectamount {
  border: none;
  width: 50px; }

.full-form {
  position: relative; }
  .full-form input[type="text"], .full-form textarea {
    width: 100%; }

.input-button {
  display: flex;
  border: none; }
  .input-button input[type="text"] {
    flex: 2;
    box-sizing: border-box;
    border-top: 1px solid #dbdbdb; }
  .input-button .btn {
    flex: 1;
    box-sizing: border-box;
    text-align: center;
    line-height: 22px;
    padding: 6px 0px;
    background: #dbdbdb;
    color: #222222;
    text-transform: uppercase;
    cursor: pointer; }
    .input-button .btn:hover {
      background: #222222;
      color: #fff; }

.editit {
  position: fixed;
  right: 44px;
  bottom: 44px;
  z-index: 1000; }

.editit a {
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 100%;
  color: white;
  background: red; }
  .editit a:hover {
    opacity: 0.7;
    background-color: #f22;
    border: none; }
  .editit a i.material-icons {
    line-height: 48px !important; }

.editlink {
  padding: 4px 20px 4px 30px;
  background: url("/static/images/pencil.png") no-repeat 10px 4px #ddd; }

.editit a:hover, .editlink:hover {
  opacity: 0.7;
  background-color: #f22;
  border: none; }

/*Buttons*/
button:hover {
  cursor: pointer; }

.btn {
  background: #222222;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  padding: 0.5em 1em;
  border: none !important;
  line-height: 1; }

.btn-light {
  font-size: 1em;
  line-height: 13px;
  background: #fff;
  color: #222222;
  cursor: pointer;
  display: inline-block;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  padding: 0.5em 1em;
  border: 1px solid #222222;
  box-sizing: border-box; }

.btn-dark-small {
  display: inline-block;
  padding: 0.5em 1em;
  box-sizing: border-box;
  background: #222222;
  color: #fff;
  font-size: 1em;
  line-height: 1em;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  border: none;
  box-sizing: border-box; }

.btn-dark {
  background: #222222;
  color: #fff;
  font-size: 1.4em;
  font-weight: bold;
  padding: 12px 28px;
  float: left;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  line-height: 22px;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center; }

a.btn, a.btn-dark {
  color: #fff; }

a.btn:hover, a.btn-dark:hover {
  background: #dbdbdb;
  color: #222222;
  border: none; }

.field {
  float: left;
  margin: 22px 0px 22px 0px; }
  .field .cap {
    color: #6E787F;
    margin-bottom: 8px;
    height: 22px;
    overflow: hidden; }
  .field .val {
    font-size: 1.4em;
    line-height: 32px;
    height: 32px;
    overflow: hidden; }
  .field .total {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    overflow: hidden; }
  .field .opt {
    float: left;
    margin-right: 10px;
    opacity: 0.3;
    cursor: pointer; }
    .field .opt.selected {
      opacity: 1; }
  .field .thumb-shpping {
    float: left;
    margin-right: 5px; }
    .field .thumb-shpping img {
      max-height: 48px; }
  .field textarea, .field input[type="text"] {
    border: 1px solid #dbdbdb;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    height: 60px; }
  .field .radio .option {
    font-size: 1.4em;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    position: relative;
    margin-bottom: 4px; }
    .field .radio .option:before {
      content: " ";
      width: 18px;
      height: 18px;
      background: #f9f9f9;
      border: 1px solid #dbdbdb;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 100%; }
    .field .radio .option:after {
      border-radius: 100%;
      width: 14px;
      height: 14px;
      background: #484848;
      right: 3px;
      top: 3px;
      position: absolute;
      z-index: 3;
      content: " ";
      transform: scale(0);
      transition: all .25s ease-out; }
    .field .radio .option.selected:after {
      transform: scale(1); }
  .field.right {
    margin-right: 0;
    float: right;
    text-align: right;
    width: auto; }

.field.big {
  width: 64%; }

.checkbox-field {
  display: block;
  float: left; }

/*Cart*/
#page-cart .product {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 22px;
  margin-bottom: 22px;
  overflow: hidden; }
  #page-cart .product h1 {
    font-size: 200%;
    line-height: 1; }
  #page-cart .product .material-box {
    width: 30px;
    height: 30px;
    float: left;
    margin-right: 7px; }
  #page-cart .product .thumb {
    float: left;
    width: 25%; }
    #page-cart .product .thumb img {
      width: 100%; }
  #page-cart .product .desc {
    width: 75%;
    float: right; }
  #page-cart .product .total {
    float: right;
    font-size: 200%;
    line-height: 1; }
  #page-cart .product .material {
    margin-top: 7px;
    line-height: 30px; }
  #page-cart .product .amount {
    margin-top: 7px; }
    #page-cart .product .amount .cap {
      float: left;
      margin-top: 4px;
      margin-left: 10px; }
  #page-cart .product .ctrls .ctrl.delete {
    background: red; }
    #page-cart .product .ctrls .ctrl.delete span {
      display: none; }
    #page-cart .product .ctrls .ctrl.delete:before {
      content: "\00d7"; }
  #page-cart .product .discounted {
    color: red; }
  #page-cart .product.ng-hide-add {
    transition: all .1s ease-in-out; }
  #page-cart .product.ng-hide-add-active {
    opacity: 0;
    height: 0; }

#page-cart .hide-after-step-one.ng-hide-add {
  transition: all .1s ease-in-out; }

#page-cart .hide-after-step-one.ng-hide-add-active {
  opacity: 0;
  height: 0; }

#page-cart .section-total {
  line-height: 1;
  margin-top: 44px;
  transition: margin .5s ease-in-out; }
  #page-cart .section-total h1 {
    text-transform: uppercase;
    font-size: 200%;
    float: left;
    margin-bottom: 11px; }
  #page-cart .section-total .total {
    float: right;
    font-size: 200%;
    line-height: 1; }
  #page-cart .section-total .discount {
    line-height: 1.2;
    font-size: 36px;
    color: #222222; }
  #page-cart .section-total .coupon {
    height: 44px;
    margin-top: 22px;
    margin-bottom: 22px; }
  #page-cart .section-total .btn {
    cursor: pointer;
    float: left;
    text-transform: uppercase;
    padding: 7px 12px;
    line-height: 22px; }
  #page-cart .section-total input {
    float: left;
    clear: left; }
  #page-cart .section-total.step2 {
    margin-top: -20px; }

#page-cart .checkout.btn {
  background: red;
  color: #fff;
  font-size: 1.4em;
  font-weight: bold;
  padding: 12px 28px;
  float: right;
  text-transform: uppercase;
  cursor: pointer;
  display: block;
  line-height: 22px;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  width: auto; }

#page-cart .section-step2 {
  font-family: Avernir, Arial, sans-serif; }
  #page-cart .section-step2.ng-hide-remove {
    transition: .5s all ease-in-out;
    transition-delay: .6s;
    opacity: 0; }
  #page-cart .section-step2.ng-hide-remove-active {
    opacity: 1; }
  #page-cart .section-step2 h3 {
    font-size: 1.2em;
    float: left;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 15px; }
  #page-cart .section-step2 .ui-select-container {
    margin-top: 15px; }
    #page-cart .section-step2 .ui-select-container .ui-select-choices.ui-select-dropdown {
      top: 39px !important; }
  #page-cart .section-step2 .check {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 10px;
    margin-top: 2px;
    background: #ccc;
    cursor: pointer; }
    #page-cart .section-step2 .check.checked svg {
      display: block; }
    #page-cart .section-step2 .check svg {
      display: none; }
      #page-cart .section-step2 .check svg path {
        fill: #222222; }
  #page-cart .section-step2 .full-form {
    border-top: 1px solid #dbdbdb;
    margin-bottom: 30px; }
  #page-cart .section-step2 input {
    width: 100%;
    color: #6E787F;
    border: none;
    box-sizing: border-box;
    border-bottom: 1px solid #dbdbdb;
    line-height: 50px;
    padding: 0;
    margin: 0;
    padding-left: 15px;
    background: transparent;
    font-weight: normal; }
  #page-cart .section-step2 .inputduo {
    display: flex; }
    #page-cart .section-step2 .inputduo input {
      flex: 1; }
    #page-cart .section-step2 .inputduo input:first-of-type {
      border-right: 1px solid #dbdbdb; }

/*Order*/
.order .tile.half, .order .product .body .half, .product .body .order .half, .order .post .body .half, .post .body .order .half {
  margin: 1em 0; }

/*SLIDER*/
.slider {
  margin-bottom: 66px; }
  .slider .swiper-container-slider, .slider .swiper-container-single {
    width: 100%; }
    .slider .swiper-container-slider .swiper-slide, .slider .swiper-container-single .swiper-slide {
      width: 100%; }
      .slider .swiper-container-slider .swiper-slide .img-holder, .slider .swiper-container-single .swiper-slide .img-holder {
        width: 100%;
        text-align: center; }
        .slider .swiper-container-slider .swiper-slide .img-holder img, .slider .swiper-container-single .swiper-slide .img-holder img {
          width: 100%; }
      .slider .swiper-container-slider .swiper-slide .desc, .slider .swiper-container-single .swiper-slide .desc {
        position: absolute;
        bottom: 10%;
        width: 100%;
        z-index: 2;
        font-size: 1.4em;
        line-height: 1;
        font-weight: normal; }
        .slider .swiper-container-slider .swiper-slide .desc h1, .slider .swiper-container-slider .swiper-slide .desc h2, .slider .swiper-container-slider .swiper-slide .desc h3, .slider .swiper-container-single .swiper-slide .desc h1, .slider .swiper-container-single .swiper-slide .desc h2, .slider .swiper-container-single .swiper-slide .desc h3 {
          font-family: "Avenir", Helvetica, Arial, sans-serif;
          font-weight: bold;
          line-height: 1; }
        .slider .swiper-container-slider .swiper-slide .desc h1, .slider .swiper-container-single .swiper-slide .desc h1 {
          font-size: 36px; }
        .slider .swiper-container-slider .swiper-slide .desc h2, .slider .swiper-container-single .swiper-slide .desc h2 {
          font-size: 28px; }
        .slider .swiper-container-slider .swiper-slide .desc h3, .slider .swiper-container-single .swiper-slide .desc h3 {
          font-size: 26px; }
        .slider .swiper-container-slider .swiper-slide .desc p, .slider .swiper-container-single .swiper-slide .desc p {
          font-size: 1.4em; }
        .slider .swiper-container-slider .swiper-slide .desc span, .slider .swiper-container-single .swiper-slide .desc span {
          display: inline-block;
          line-height: inherit; }
      .slider .swiper-container-slider .swiper-slide .call-to-action, .slider .swiper-container-single .swiper-slide .call-to-action {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        background: red;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.4em;
        padding: 0.5em 1em;
        float: right; }
        .slider .swiper-container-slider .swiper-slide .call-to-action:hover, .slider .swiper-container-single .swiper-slide .call-to-action:hover {
          border: none;
          opacity: 0.7;
          background-color: #f22; }
      .slider .swiper-container-slider .swiper-slide .call-to-action-holder, .slider .swiper-container-single .swiper-slide .call-to-action-holder {
        position: absolute;
        z-index: 2;
        top: 25%;
        width: 100%; }
  .slider .swiper-container-single .swiper-button-prev, .slider .swiper-container-single .swiper-button-next, .slider .swiper-container-single .swiper-pagination-bullet-active {
    display: none; }

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ff0000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ff0000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-pagination-bullet-active {
  background: #ff0000; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px; }

.slider-desc {
  margin-bottom: 88px; }

/*Swiper Catousel*/
.carousel {
  width: 100%;
  /*height: 420px;*/
  position: relative; }
  .carousel .swiper-container-carousel {
    width: 100%;
    height: 100%; }
    .carousel .swiper-container-carousel .swiper-slide {
      width: 320px;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .carousel .swiper-container-carousel .swiper-slide .product-tile {
        width: 100%;
        text-align: left; }
  .carousel .swiper-button-prev {
    left: 0;
    margin-left: -5%; }
  .carousel .swiper-button-next {
    right: 0;
    margin-right: -5%; }

/*Responsive*/
@media screen and (max-width: 1060px) {
  .container {
    width: 90%; } }

@media screen and (min-width: 993px) {
  .hide-on-large-only {
    display: none; } }

@media screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none; } }

@media screen and (max-width: 600px) {
  .hide-on-small-only {
    display: none; } }

@media screen and (max-width: 1148px) {
  .carousel .swiper-buttons {
    display: none; } }
