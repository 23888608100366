/* main.css */

@import "var";

body {
	font-family: $helvetic;
	font-size: $normaltext;
	line-height: $lineheight;
	font-weight:600;
	background: #fff;
	color: $maincolor;
	overflow-x: hidden;
}

h1, h2, h3, h4 {
	font-family: $avenir;
}

h1 {
	line-height:1;
}

h2, h3, h4 {
	line-height:1.6;
}

h2 { font-size: $titletext; }
h3 { font-size: $bigtext; }
h4 { font-size: $normaltext; font-style:italic; }

hr {
	border-top: 0px solid #fff;
	border-bottom: 1px solid $maincolor;
	margin: 0px;
	height: 0px; }

/*Links*/

a, a:visited {
	color: $maincolor;
	text-decoration: none; }

a:hover {
	color: $maincolor;
	text-decoration: none;
	border-bottom: $borderdark;
	}

a.no-border, a.logo, .slideCall a, a.buy, a.close {
	border-bottom: none!important;
	}

a.callLink {
	color: red;
	&:hover { border-bottom: 1px solid red; }
}

a.call-btn, button.call-btn {
	display: inline-block;
	font-family: $avenir;
	font-size:$normaltext;
	line-height:$normaltext;
	background:red;
	color: #fff;
	padding: 0.5em 1em;
	border:none!important;
	box-sizing: border-box;
	&:hover { opacity:0.7; background-color: #f22; }
}

.fakelink { cursor: pointer; }

.close {
	float: right;
}

a.delete { color: red!important;
	&:hover { border-bottom: none; }
}

a[ng-click]{ cursor: pointer; }

.delbutton {
	background-color: red; }

/*Align*/

.wrapper {
	width: 100%;
}

.wrapper.main {
	min-height: calc(100vh - 336px);
	margin-bottom: 66px;
	a.nounderline { border-bottom: none; }
}

.container {
	position: relative;
	width: 1000px;
	margin: auto; }

.topmargin {
	margin-top: $topmargin;
}
.pagemenumargin {
	margin-top: $pagemenumargin;
}

.half { width: 50%; }
.third { width: 33.33%; }
.quarter { width: 25%; }
.two-thirds { width: 66.66%; }
.one-third { width: 33.33%; }

.tile {
	float: left;
	box-sizing: border-box;
	&.half {
		width: 48%;
		&:nth-of-type(2n+1) { margin-right:2%; }
		&:nth-of-type(2n+2) { margin-left:2%; }
	}
	&.third {
		width: 31%;
		&:nth-of-type(3n+1) { margin-right:2%; }
		&:nth-of-type(3n+2) { margin-right:1%; margin-left:1%; }
		&:nth-of-type(3n+3) { margin-left:2%; }
	}
	&.quarter {
		width: 23%;
		&:nth-of-type(4n+1) { margin-right:2%; }
		&:nth-of-type(4n+2) { margin-left: 1%; margin-right: 1%; }
		&:nth-of-type(4n+3) { margin-left: 1%; margin-right: 1%; }
		&:nth-of-type(4n+4) { margin-left: 2%; }
	}
	&.two-thirds {
		width: 64%;
		margin-right:2%;
	}
	&.one-third {
		width: 32%;
		margin-left:2%;
	}
}

.third.center, .quarter.center { text-align: center; }
.half.right, .third.right, .quarter.right { text-align: right; float: right; }

.flex-wrapper {
	display: flex;
	flex-wrap: wrap;
	.flex-items { flex: 1; }
}

.flex-items {
	display: flex;
	.flex-item {
		box-sizing: border-box;
		flex: 1;
	}
}

.block { width:100%; margin-bottom:2*$lineheight; }
.block-big { width:100%; margin-bottom:4*$lineheight; }
.block.hr { padding-bottom:44px; }
.block-small { width:100%; margin-bottom:$lineheight; }

.fullwidth { width: 100%; }
.per90 { width: 90%; }

.vertical-half-space { height: $lineheight/2; }

.table td {
	padding: 4px 20px 4px 0px; }

.dotted tr {
	border-bottom: 1px dotted $graycolor; }

.table-even {
	width: 100%;
	table-layout: fixed; }

.table-even input[type="text"] { width: 95%; }

.cols2 { column-count: 2; -webkit-column-count: 2; -moz-column-count: 2; }
.cols3 { column-count: 3; -webkit-column-count: 3; -moz-column-count: 3; }
.cols4 { column-count: 4; -webkit-column-count: 4; -moz-column-count: 4; }

.cols2,
.cols3,
.cols4, {
	-webkit-column-gap: 30px; /* Chrome, Safari, Opera */
    -moz-column-gap: 30px; /* Firefox */
    column-gap: 30px;
}
/*Color and text size*/

.smalltext {font-size: $smalltext;}
.normaltext {font-size: $normaltext;}
.mediumtext {font-size: $mediumtext;}
.bigtext {font-size: $bigtext;}
.titletext {font-size: $titletext;}

.transpw { background: $transwhite; }

.message { margin: $lineheight 0px; }

#error-message, .flashes, .discounted { color: red; }

.flashes {
	width:100%;
	margin-bottom:2*$lineheight;
	a {color: red;
		&:hover { border-color:red; }
	}
}

/*Common*/

.capitalize { text-transform: capitalize; }
.smaller { 	font-size: 85%;}
.italic { font-style:italic; }
.pointer {cursor:pointer}

.fright { float: right; }
.fleft { float: left; }

.rel { position: relative; }
.abs { position: absolute; }
.fixed { position: fixed; }
.mosttop {
	position: absolute;
	z-index: 9999999; }

.hidden { display: none; }
.visible { overflow: visible !important; }

.padd { padding: 1em 2em; }
.no-padding { padding: 0!important; }

.hr { border-bottom: 1px solid $maincolor;}

.clearfix {
	width: 100%; clear: both;
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

.info {
	background-color: white;
	background-repeat: no-repeat;
	background-position: right 10px;
	background-size: auto 130px;
	padding: 5px;
	opacity: 0.8;
	z-index: 2; }


.loader {
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	z-index:3;
	background-color:$transwhite;
	img {
		position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -16px;
			margin-top: -16px;
			display: block;
	}
}

.scrolX {
	overflow-x:scroll;
}

/*Social*/

.socicons .flex-item { margin-bottom:5%; }

a.socicon-btn {
	display: block;
	width: $sociconsize;
	height: $sociconsize;
	font-size: 0.4*$sociconsize;
	line-height: $sociconsize;
	margin:0;
	text-align:center;
	font-family: $avenir;
	color: white;
	background: $maincolor;
	border-radius:100%;
	vertical-align:middle;
	&:hover{
		border-bottom: none;
	}
	&:before {
		line-height: $sociconsize!important;
	}
	&.socicon-facebook:hover { background: #3e5b98;}
	&.socicon-instagram:hover { background: #e4405f;}
	&.socicon-twitter:hover { background: #4da7de;}
	&.socicon-googleplus:hover { background: #dd4b39;}
	&.socicon-pinterest:hover { background: #c92619;}
	&.socicon-linkedin:hover { background: #3371b7;}
	&.socicon-youtube:hover { background: #e02a20;}
}

.social-btns {
	a.socicon-btn {
		display: inline-block;
		width: 42px;
		min-width: 42px;
		height: 42px;
		margin:0 11px 6px 0;
		font-size: 21px;
		line-height: 42px;
		&:before {
			line-height: 42px!important;
		}
		&.socicon-facebook { background: #3e5b98;}
		&.socicon-instagram { background: #e4405f;}
		&.socicon-twitter { background: #4da7de;}
		&.socicon-googleplus { background: #dd4b39;}
		&.socicon-pinterest { background: #c92619;}
		&.socicon-linkedin { background: #3371b7;}
		&.socicon-youtube { background: #e02a20;}
		&:hover{
			background: $maincolor;
			cursor:pointer;
		}
	}
}

/*Comments*/


.comment {
	position:relative;
	margin-bottom: 11px;
	b { font-weight: bold; }
	p {
		font-weight: normal;
		img { max-height: 16px; }
	}
	input[type="text"], textarea {
		width: 100%;
		&:required, &:invalid {
		    box-shadow:none;
		}
	}
}
	.comment.hr {
		border-bottom: 1px solid $borderinput;
		padding-bottom: 11px;}
	.comment.hr:last-child {
		border-bottom: none;
		padding-bottom: 0px; }



/*Header*/


#header{
	padding-bottom: 0;
	width: 100%;
	position: fixed;
	background: $transwhite;
	transition: margin .2s ease-out;
	#topmenu {
		font-size: 16px;
		padding: 8px 0px;
		font-weight: bold;
		margin-top: 2px;
		ul li {
			text-transform: lowercase;
			display: inline;
			margin-right: 32px;
			&.active a {
				border-bottom: $borderdark;
			}
			ul {
				display: none;
			}
		}
		ul.top-nav {
			li {
				i.material-icons {
					display:none;
				}

			}
		}
		#cartlangs {
			float: right;
			#cart { float: right; margin-left: 20px;}
			#l10n{
				float: right;
				position: relative;
				div.current { line-height:1px; }
				ul{
					position: absolute;
					top: 22px;
					left: 0px;
					li {
						display: block;
						margin-right: 0;
						line-height:1px;
						img{
							margin-top: 0px;
						}
						a{
							margin: 0;
							display:block;
							padding:1px 0px;
							&:hover { border: none; }
						}
					}
					&.ng-hide-remove{
						opacity: 0;
					}
					&.ng-hide-remove-active{
						opacity: 1;
					}
					&.ng-hide-add{
						opacity: 1;
						transition: all .5s;
					}
					&.ng-hide-add-active{
						opacity: 0;
					}
				}
			}
		}
	}
	#logodiv{
		width: 100%;
		height: 59px;
		line-height: 52px;
		font-size: 40px;
		font-family: $avenir;
		color: $maincolor;
		border-bottom: 1px solid rgba(34,34,34,1);
		transition: border-color 0.5s linear;
		overflow: hidden;
		.holder{
			padding-top: 4px;
			padding-bottom: 2px;
			transition: margin .2s ease-out;
			.logo { display:block; float: left; line-height: 52px; }
			h1 { float: right; line-height: 52px; }
		}
		.navbar-toggle {
			display: none;
		}
	}
	&.no-border{
		#logodiv{
			border-bottom: 1px solid rgba(0,0,0,0);
		}
	}
}

.dropdown li ul {
	display: none;
	position: absolute;
	z-index: 2;
	padding-left: 12px;
	line-height: 19px;
	font-size: 16px; }

.dropdown:hover li ul {
	display: block; }


/*Content*/


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
	display: none !important;
}


/*Home*/

.index .products { margin-bottom:4*$lineheight!important; }

/*Tag*/


.shopBanner {
	padding-bottom:44px;
}
.shopBanner img { display: block; width:100%; }

.taginfo {
	margin: 0px 0px 44px 0px; }

section.collection {
	padding-bottom:2*$lineheight;
}

.products {
	margin-bottom:2*$lineheight;
}

/*Shop*/

.shop-block{
	.shop-title{
		text-transform: uppercase;
		padding-top: 100px;
		font-family: $avenir;
		font-size: $titletext;
		line-height: 2*$lineheight;
		text-align:center;
		margin-bottom: 2*$lineheight;
		border-bottom: 1px solid $borderinput;
	}
	&:first-of-type{
		.shop-title{
			padding-top: 0;
		}
	}
}

.currency {
	font-family: $helvetic;
	margin: 0 0.25em;
	font-size: 0.8em;
	line-height:1em;
	 }

.product-tile {
	overflow: hidden;
	margin-bottom:$lineheight*2;
	.desc {
		height: 116px;
		overflow: hidden;
		text-align:center;
		h1 {
			font-size: $bigtext;
			text-align:center;
			margin-top: 10px;
			margin-bottom:5px; }
		.props {
			height:$lineheight;
			font-weight: normal;
			text-align:center;
			margin-bottom: 5px;
			overflow:hidden; }
		.price-buy {
			width:auto;
			display:inline-block;
		}
		.price-holder, .buy, .discount {
			float: left;
			position:relative;
			width: 80px;
			height: 34px;
			padding: 6px 0;
			font-size: $bigtext;
			line-height: 1;
			text-align:center;
			font-weight: bold;
			box-sizing: border-box;
			overflow: hidden;
			}
		.price-holder {
			background: #e3e3e3;
			&.discounted { color: red; }
			}
		.discount {
			color: red;
			background: white;
		}
		.buy {
			color: #fff;
			text-transform: uppercase;
			font-size: $mediumtext;
			padding: 8px 0;
			background: red;
			margin-left: 1px;
			-moz-transition: background 0.2s;
			-o-transition: background 0.2s;
			-webkit-transition: background 0.2s;
			transition: background 0.2s;
			cursor: pointer;
			}
			.buy:hover {
				background: $maincolor; }
	}
	.thumbdiv {
		width: 100%;
		height: 0;
		padding-bottom: 75%;
		position:relative;
		overflow: hidden;
		text-align:left;
		a  {
			display:block;
			position:absolute;
			height:100%;
			width:100%;
			left:0;
			top:0;
			border-bottom: none;
			text-align:center;
		}
		img { height:100%; }
	}
}


/* Product */

.product {
	h2 { margin-bottom:$lineheight;}
	h3 { margin-bottom:11px;}
	.left-col {
		width: 60%;
		float:left;
	}
	.right-col {
		width: 38%;
		float:right;
		margin-bottom: $lineheight;
	}
	.body {
		margin-top: 2*$lineheight;
		p {
			padding-bottom: $lineheight ;
		}
		.half, .third {
			@extend .tile;
			margin-bottom:$lineheight;
		}
		img  { width: 100%; }
		a {
			border-bottom: $borderdark;
			&:hover {
				border-bottom: none;
			}
		}
	}
	.specs {
		margin-top: 6px;
		td {
			padding: 4px 10px 4px 0px;
			&:last-child { padding-right: 0; }
		}
	}
	.quote {
		font-family: $avenir;
		font-size: $titletext;
		line-height: 1.6;
		text-align: left;
		padding: 3*$lineheight 0;
		margin: 4*$lineheight 0;
		background: #efefef;
		a {border:none;}
		.quote-container {
			width:90%;
			margin:auto;
			display:flex;
			justify-content: space-around;

		}
		.quote-text {
			width:80%;
			text-align:center;
		}
		.quote-author {
			max-width:12%;
			font-style: normal;
			text-align: right;
		}
		.quote-img {
			max-width:12%;
			margin-top:11px;
			img {
				width:100%;
			}
		}
	}
}


.setup-links .setup-link {
	display:block;
	float:left;
	width:22px;
	height:22px;
	text-align:center;
	margin-right:0px;
	cursor: pointer;
	border-top: 1px solid $maincolor;
	border-right: 0px solid $maincolor;
	border-bottom: 1px solid $maincolor;
	border-left: 1px solid $maincolor;
	&.current{
		background:$maincolor;
		color: #fff;
	}
	&:last-child { border-right: 1px solid $maincolor; }
}
.setup-link:hover { background:$maincolor; color:#fff;}


#pricediv {
	text-align: left;
	width: 100%;
	font-family: $avenir;
	.addtocart, .discount {
		float: right;
		text-transform: uppercase;
		width: 50%;
		min-width: 140px;
		text-align: center;
		border: none;
		line-height: 44px;
		cursor: pointer;
		box-sizing: border-box;
		position: relative;
		-moz-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}
	.addtocart {
		font-size: $mediumtext;
		background: red;
		color: white;
	}
	.discount {
		color: red;
		font-size: 32px;
	}
	.price-holder {
		float: left;
		margin-bottom: $lineheight;
		.price {
			position:relative;
			float:left;
			line-height: 44px;
			font-size: $hugetext;
			.new-price { color:red; }
			&.big-price {
			font-size: 32px; }
		}
	}
}

svg.strike-red {
	position:absolute;
	top:0;
	left:0;
	line {
		stroke:rgba(255,0,0,0.6);
		stroke-width:4;}
}

.materials{
	.col{
		width: 50%;
		float: left;
	}
	.col-full{
		width: 100%;
		float: left;
	}
	.item{
		width: 100%;
		float: left;
		cursor: pointer;
		margin-bottom: 10px;
		.name {
			height: 30px;
			line-height: 1;
			word-wrap:break-word;
			display: table-cell;
			vertical-align:middle;
		}
		.check {
			width: 30px;
			height: 30px;
			float: left;
			margin-right: 10px;
			position: relative;
			svg {
				position: absolute;
				top: 7px;
				left: 5px;
				opacity: 0;
				-moz-transition: opacity 0.25s;
				-o-transition: opacity 0.25s;
				-webkit-transition: opacity 0.25s;
				transition: opacity 0.25s;
				path { fill: #fff; }
			}
			&.checked svg { opacity: 1; }
		}
		.ctrls {
			float:left;
		}
	}
}

.ctrls {
	margin-right: 10px;
	.ctrl{
		width: 30px;
		height: 30px;
		background: $maincolor;
		font-size: 24px;
		line-height: 30px;
		color: white;
		text-align: center;
		line-height: 30px;
		font-weight: bold;
		float: left;
		padding: 0;
		cursor: pointer;
	}
	.input{
		height: 30px;
		width: 34px;
		text-align: center;
		background:#fff;
		line-height: 30px;
		border:2px solid $maincolor;
		box-sizing:border-box;
		float: left;
	}
}

.materials .item.beech .check svg path { fill: #fff; }
.materials .item.maple .check svg path { fill: $maincolor; }
.materials .item.glass .check svg path { fill: $maincolor; }
.materials .item.dark .check svg path { fill: #fff; }


.dif-circle{
	width: 10px;
	height: 10px;
	border:2px solid $maincolor;
	float: left;
	border-radius: 100%;
	margin-right: 4px;
	margin-top: 2px;
}
.dif1 {
	.dif-circle:nth-of-type(1){
		background:$maincolor;
	}
}
.dif2 {
	.dif-circle:nth-of-type(1){
		background:$maincolor;
	}
	.dif-circle:nth-of-type(2){
		background:$maincolor;
	}
}
.dif3 {
	.dif-circle:nth-of-type(1){
		background:$maincolor;
	}
	.dif-circle:nth-of-type(2){
		background:$maincolor;
	}
	.dif-circle:nth-of-type(3){
		background:$maincolor;
	}
}
.dif4 {
	.dif-circle{
		background:$maincolor;
	}
	.dif-circle:nth-of-type(5){
		background:transparent;
	}
}
.dif5 {
	.dif-circle{
		background:$maincolor;
	}
}

.stars{
	.star{
		float: left;
		margin-right: 0px;
		cursor: pointer;
		transition:all .2s;
		svg{
			width: 20px;height: 20px;
			polygon{
				fill:#fff;
				stroke:$maincolor;
				stroke-width:14px;
				transition: fill .2s;
			}
		}
		&.over{
			svg polygon{
				fill:$maincolor;
			}
		}
	}
	&.rated{
		.star:not(.over){
			transform:scale(0.8);
		}
	}
}

.sprite-container {
	width: 100%;
	padding-bottom:85%;
	position:relative;
}

.sprite-holder {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	overflow: hidden;
}

#mySpriteSpin	{
	cursor:url("/static/images/rotate.png"),url("/static/images/rotate.png"),auto;
}

#mySpriteLoader	{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	overflow: hidden;
	img {
		max-width: 100%;
		max-height: 100%;
	}
}

#rotateTooltip {
	display: none;
	position: absolute;
	bottom: 20%;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 3;
	img {
		vertical-align:middle;
		margin-right: 10px;
		width:24px;
	}
}

.download {
	.downloads-icon {
		float:left;
		margin-right: 2%;
		width:25%;
		img { width:100%; }

	}
	.material-icons {
		display:block;
		float:left;
		width:32px;
		height:32px;
		margin-right:2%;
		line-height:32px;
		text-align:center;
		border-radius: 100%;
		background:white;
		color: $maincolor;
		border: 2px solid $maincolor;
		box-sizing:border-box;
	}
	.title { line-height:32px; font-size: $mediumtext; }
	& a:hover {
		/*border:none;*/
		.material-icons {
			color:white;
		background: $maincolor;
		}
		.downloads-icon { opacity: 0.7; }
	}
}

/*# sourceMappingURL=product.css.map */



/*Page*/


.page {
	padding: 44px 0px;
	&.with-menu { padding-top: 88px; }
}

#pageMenu {
	width: 100%;
	position: absolute;
	z-index: 10001;
	}
	#pageMenu.stick {
		position: fixed;
		bottom: auto;
		top: 60px;
	}


.pageMenu {
	ul {
		position:relative;
		white-space:nowrap;
		li {
			text-transform: uppercase;
			display: inline-block;
			margin-right: 3%;
			padding: 0.5*$lineheight 0 0.5*$lineheight 0;
			&:last-child { margin-right: 0; }
			a {
				display: block;
				box-sizing:border-box;
				margin-bottom:1px;
				&:hover { margin-bottom:0;}
			}

		}
		li.activeitem a {
			border-bottom: $borderdark;
		}
	}
}

.post {
	.body {
		.half, .third {
			@extend .tile;
			margin-bottom:$lineheight;
		}
		img {
			max-width: 100%;
			margin-bottom:$lineheight;
		}
	}
	img.fullwidth {
		width: 100%!important;
		margin-bottom:$lineheight;
	}
	img.left50 {
		width: 49%!important;
		float:left;
		margin: 0 2% $lineheight 0;
	}
	img.right50 {
		width: 49%!important;
		float:right;
		margin: 0px 0 $lineheight 2%;
	}
	blockquote {
		margin: 11px $lineheight;
		font-style:italic;
	}
	h1 {
		font-size: $titletext;
		margin: 11px 0;
	}
	h2 {
		font-size: $titletext;
		margin-bottom: $lineheight;
	}
	h3 {
		font-size: $bigtext;
		margin-bottom: $lineheight;
	}
	a {
		border-bottom: $borderdark;
		&:hover {
			border-bottom: none;
		}
	}
}

.video-wrapper {
	position: relative;
	width:100%;
	height: 0;
	padding-top: 28.2%;
	padding-bottom: 28.2%;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.blogpost {
	display: block;
	position:relative;
	box-sizing: border-box;
	margin-bottom:$lineheight;
	h1 {
		font-size: $bigtext;
		border-bottom: 1px solid transparent;
		margin-bottom:5px;
		cursor: pointer;
		a:hover {
			border-bottom: 1px solid $maincolor; }
	}
	h2 { font-size: 16px;}
	.blogThumb {
		width: 100%;
		height:0;
		padding-bottom: 56.25%;
		position:relative;
		overflow: hidden;
		a  {
			display:block;
			position:absolute;
			left:0;
			top:0;
			height:100%;
			width:100%;
			border-bottom: none;
			text-align:center;
		}
		img { width: 100%; }
	}
	.desc {
		height: 138px;
		padding-top: 0.5*$lineheight;
		overflow:hidden;
	}
	&.slide {
		.blogThumb { padding-bottom: 43%; }
		.desc {
			a { color: $graycolor; }
			&.active a {color: $maincolor; }
		}
	}
}

.quotes {
	&.tile { margin-bottom:2*$lineheight; }
	.imgholder {
		position:relative;
		height: 0;
		padding-bottom: 43%;
		margin-bottom: 0.5*$lineheight;
		img {
			position:absolute;
			bottom: 0;
			max-width: 100%;
			max-height:100%;
			display:block;
		}
	}
	.desc {
		height: 6*$lineheight;
		overflow: hidden;
		font-style: italic;
		font-size: 16px;
	}
}


/*Contact*/

.contacts {
	h2 {
		line-height: $sociconsize;
		margin-bottom: $lineheight;
	}
}

.business-card {
	font-size:120%;
	line-height:1.6;
	h3 {
		font-size:160%;
		line-height:1.6;
	}
}

/*Footer*/

footer{
	background: #f2f2f2;
	padding: 30px 0;
	h3{
		font-size: $bigtext;
		line-height:28px;
		margin-bottom: 10px;
	}
	ul li {
		line-height: 28px;
	}
	.footer-imgs {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		line-height: 1px;
		height:24px;
		margin: 0 auto 4px auto;
		a { border: none; }
		img {
			max-height: 100%;
			margin:0 4px 4px 0;
		}
	}
}

a.socicon-link {
	display: inline-block;
	width: 28px;
	min-width: 28px;
	height: 28px;
	margin:0 1.6% 4px 0;
	font-size: 14px;
	line-height: 28px;
	text-align:center;
	color: white;
	background: $maincolor;
	border-radius:100%;
	vertical-align:middle;
	&:hover{
		border-bottom: none;
		cursor:pointer;
	}
	&:before {
		line-height: 28px!important;
	}
	&.socicon-facebook:hover { background: #3e5b98;}
	&.socicon-instagram:hover { background: #e4405f;}
	&.socicon-twitter:hover { background: #4da7de;}
	&.socicon-googleplus:hover { background: #dd4b39;}
	&.socicon-pinterest:hover { background: #c92619;}
	&.socicon-linkedin:hover { background: #3371b7;}
	&.socicon-youtube:hover { background: #e02a20;}
}

/*# sourceMappingURL=main.css.map */
/*# sourceMappingURL=index.css.map */

/*Cookie consent*/

#cookie-consent{
	padding: 0.5*$lineheight 0;
	width: 100%;
	position: fixed;
	left:0;
	bottom:0;
	background: white;
	box-sizing: border-box;
	border-top: $borderlight;
	.consent-container {
		display:flex;
		justify-content: space-between;
		padding: 0.5em 2%;
		.consent-text {
		}
		.btns {
			min-width: 333px;
		}
		button, a.btn, a.btn-light, a.btn-dark-small {
			margin-left:3px;
		}
	}
	a.close {
		position:absolute;
		right:0;
		font-size:$hugetext;
		color: red;
		display:block;
		padding: 4px 6px;
	}
}

/*Forms*/

fieldset {
	border: none; }

label { vertical-align: top; }

.inputlink {
	display: inline-block;
	background: $maincolor;
	border: none !important;
	color: white;
	font-size: $bigtext;
	line-height: $lineheight;
	padding: 6px 14px;
	margin-right: 14px;
	cursor: pointer; }

.inputlink:hover {
	background: $graycolor; }

.inputlink.delbutton {
	background: red;}

a.inputlink, .inputlink a {
	text-decoration: none;
	color: white; }

input[type="text"], textarea {
	font-family: Helvetica, Arial, sans-serif;
	font-size: $normaltext;
	line-height: $lineheight;
	font-weight:600;
	background: #fff;
	color: $maincolor;
	border: none;
	border-bottom: 1px solid $borderinput;
	padding: 6px 0px 6px 15px;
	box-sizing: border-box;
	&.disabled { background:  $borderinput; }
	&.disabled.enabled { background:  #fff; }
	}

input[type="number"] {
	width: 50px;
	border: 1px solid $borderinput;
	font-weight: bold; }

input.subproducts-amount {
	width: 50px; }

select {
	border: 1px solid $borderinput;
	padding: 0px;
	font-weight: bold; }

select.selectamount {
	border: none;
	width: 50px; }

.full-form {
	position:relative;
	input[type="text"], textarea {
		width:100%;
	}
}

.input-button {
	display:flex;
	border: none;
	input[type="text"] {
		flex:2;
		box-sizing: border-box;
		border-top: 1px solid $borderinput;
	}
	.btn {
		flex:1;
		box-sizing: border-box;
		text-align:center;
		line-height: $lineheight;
		padding: 6px 0px;
			background:$borderinput;
			color: $maincolor;
			text-transform: uppercase;
			cursor: pointer;
			&:hover {
				background:$maincolor;
			color: #fff;
			}
		}
}

.editit {
	position: fixed;
	right: 44px;
	bottom: 44px;
	z-index: 1000; }

.editit a {
	display:block;
	width:48px;
	height:48px;
	line-height:48px;
	text-align:center;
	border-radius: 100%;
	color:white;
	background:red;
	&:hover { opacity:0.7; background-color: #f22; border:none;  }
	i.material-icons { line-height:48px!important; }
}

.editlink {
	padding: 4px 20px 4px 30px;
	background: url("/static/images/pencil.png") no-repeat 10px 4px #ddd; }

.editit a:hover, .editlink:hover { opacity:0.7; background-color: #f22; border:none; }

/*Buttons*/

button:hover { cursor:pointer; }

.btn {
	background:$maincolor;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: $avenir;
	padding: 0.5em 1em;
	border:none!important;
	line-height:1;
}


.btn-light {
	font-size:$normaltext;
	line-height:13px;
	background:#fff;
	color: $maincolor;
	cursor: pointer;
	display: inline-block;
	font-family: $avenir;
	padding: 0.5em 1em;
	border:1px solid $maincolor;
	box-sizing: border-box;
}

.btn-dark-small {
	display: inline-block;
	padding: 0.5em 1em;
	box-sizing:border-box;
	background:$maincolor;
	color: #fff;
	font-size:$normaltext;
	line-height:$normaltext;
	font-family: $avenir;
	cursor: pointer;
	border:none;
	box-sizing: border-box;
}

.btn-dark {
	background:$maincolor;
	color: #fff;
	font-size: $bigtext;
	font-weight: bold;
	padding: 12px 28px;
	float: left;
	text-transform: uppercase;
	cursor: pointer;
	display: block;
	line-height: $lineheight;
	margin-top: 1em;
	margin-bottom: 1em;
	text-align:center;
}

a.btn, a.btn-dark {color: #fff;}
a.btn:hover, a.btn-dark:hover {
	background:$borderinput;
	color:$maincolor;
	border:none;
}

.field {
	float: left;
	margin:	$lineheight 0px $lineheight 0px;
	.cap{
		color: $graycolor;
		margin-bottom: 8px;
		height: $lineheight;
		overflow:hidden;
	}
	.val{
		font-size: $bigtext;
		line-height: 32px;
		height: 32px;
		overflow:hidden;
	}
	.total{
		font-size: 32px;
		line-height:32px;
		height: 32px;
		overflow: hidden;
	}
	.opt{
		float: left;
		margin-right: 10px;
		opacity: 0.3;
		cursor: pointer;
		&.selected{
			opacity: 1;
		}
	}
	.thumb-shpping {
		float:left;
		margin-right: 5px;
		img { max-height: 48px; }
	}
	textarea, input[type="text"] {
		border:1px solid $borderinput;
		width: 100%;
		box-sizing:border-box;
		padding: 10px;
		height: 60px;
	}
	.radio{
		.option{
			font-size: $bigtext;
			text-transform: uppercase;
			padding-right: 30px;
			cursor: pointer;
			position: relative;
			margin-bottom: 4px;
			&:before{
				content:" ";
				width: 18px;
				height: 18px;
				background:#f9f9f9;
				border:1px solid $borderinput;
				position: absolute;
				right: 0;
				top: 0;
				border-radius: 100%;
			}
			&:after{
				border-radius: 100%;
				width: 14px;height: 14px;
				background:#484848;
				right: 3px;
				top: 3px;
				position: absolute;
				z-index: 3;
				content:" ";
				transform:scale(0);
				transition:all .25s ease-out;
			}
			&.selected{
				&:after{
					transform:scale(1);
				}
			}
		}
	}
	&.right{
		margin-right: 0;
		float: right;
		text-align: right;
		width: auto;
	}
}
.field.big { width: 64%; }

.checkbox-field { display:block; float: left;}

/*Cart*/

#page-cart{
	.product{
		width: 100%;
		box-sizing:border-box;
		position: relative;
		padding-bottom: $lineheight;
		margin-bottom: $lineheight;
		overflow:hidden;
		h1{
			font-size: 200%;
			line-height: 1;
		}
		.material-box{
			width: 30px;
			height: 30px;
			float: left;
			margin-right: 7px;
		}
		.thumb{
			float:left;
			width: 25%;
			img {
				width:100%;
			}
		}
		.desc {
			width:75%;
			float:right;
		}
		.total{
			float:right;
			font-size: 200%;
			line-height: 1;
		}
		.material {
			margin-top: 7px;
			line-height: 30px;
		}
		.amount{
			margin-top: 7px;
			.cap{
				float: left;
				margin-top: 4px;
				margin-left:10px;
			}
		}
		.ctrls {
			.ctrl.delete {
				background: red;
				span { display: none; }
				&:before { content: "\00d7"; }

			}
		}
		.discounted { color:red; }
		&.ng-hide-add{
			transition: all .1s ease-in-out;
		}
		&.ng-hide-add-active{
			opacity: 0;
			height: 0;
		}
	}
	.hide-after-step-one{
		&.ng-hide-add{
			transition: all .1s ease-in-out;
		}
		&.ng-hide-add-active{
			opacity: 0;
			height: 0;
		}
	}
	.section-total{
		line-height: 1;
		margin-top: $lineheight*2;
		h1{
			text-transform: uppercase;
			font-size: 200%;
			float: left;
			margin-bottom: $halfline;
		}
		.total{
			float: right;
			font-size: 200%;
			line-height:1;
		}
		.discount{
			line-height:1.2;
			font-size: $titletext;
			color: $maincolor;
		}
		.coupon{
			height: 2*$lineheight;
			margin-top: $lineheight;
			margin-bottom: $lineheight;
		}
		.btn{
			cursor: pointer;
			float: left;
			text-transform: uppercase;
			padding: 7px 12px;
			line-height: 22px;
		}
		input{
			float: left;
			clear: left;
		}
		transition:margin .5s ease-in-out;
		&.step2{
			margin-top: -20px;
		}
	}
	.checkout.btn{
		background:red;
		color: #fff;
		font-size: $bigtext;
		font-weight: bold;
		padding: 12px 28px;
		float: right;
		text-transform: uppercase;
		cursor: pointer;
		display: block;
		line-height: $lineheight;
		margin-top: 1em;
		margin-bottom: 1em;
		text-align:center;
		width: auto;
	}

	.section-step2{
		font-family: Avernir, Arial, sans-serif;
		&.ng-hide-remove{
			transition:.5s all ease-in-out;
			transition-delay:.6s;
			opacity: 0;
		}
		&.ng-hide-remove-active{
			opacity: 1;
		}

		h3{
			font-size: $mediumtext;
			float: left;
			text-transform: uppercase;
			font-weight: normal;
			margin-bottom: 15px;
		}
		.ui-select-container{
			margin-top: 15px;
			.ui-select-choices.ui-select-dropdown{
				top: 39px!important;
			}
		}
		.check{
			width: 18px; height: 18px;
			float: left;
			margin-right: 10px;
			margin-top: 2px;
			background:#ccc;
			cursor: pointer;
			&.checked{
				svg{
					display: block;
				}
			}
			svg{
				display: none;
				path{
					fill: $maincolor;
				}
			}
		}
		.full-form{
			border-top: 1px solid $borderinput;
			margin-bottom: 30px;
		}
		input{
			width: 100%;
			color: $graycolor;
			border:none;
			box-sizing:border-box;
			border-bottom: 1px solid $borderinput;
			line-height: 50px;
			padding: 0;
			margin: 0;
			padding-left: 15px;
			background:transparent;
			font-weight: normal;

		}
		.inputduo{
			display: flex;
			input{
				flex:1;
			}
			input:first-of-type{
				border-right: 1px solid $borderinput;
			}
		}

	}
}

/*Order*/

.order {
	.tile.half { margin: 1em 0; }
}


/*SLIDER*/

.slider {
	margin-bottom: 3*$lineheight;
	.swiper-container-slider, .swiper-container-single {
		width: 100%;
		.swiper-slide {
			width: 100%;
			.img-holder {
				width: 100%;
				text-align:center;
				img {
					width: 100%;
				}
			}
			.desc{
				position: absolute;
				bottom:10%;
				width: 100%;
				z-index:2;
				font-size: $bigtext;
				line-height:1;
				font-weight:normal;
				h1, h2, h3 {
					font-family: $avenir;
					font-weight: bold;
					line-height:1;
				}
				h1 { font-size: 36px; }
				h2 { font-size: 28px; }
				h3 { font-size: 26px; }
				p { font-size: $bigtext; }
				span {
					display:inline-block;
					line-height:inherit;
				}
			 }
			.call-to-action{
				font-family: $avenir;
				background:red;
				color: #fff;
				text-transform: uppercase;
				font-size: $bigtext;
				padding: 0.5em 1em;
				float:right;
				&:hover { border:none; opacity:0.7; background-color: #f22; }
			}
			.call-to-action-holder{
				position: absolute;
				z-index:2;
				top:25%;
				width: 100%;
			}
		}
	}
	 .swiper-container-single {
	 	.swiper-button-prev, .swiper-button-next, .swiper-pagination-bullet-active{
			display: none;
		}
	 }
}

.swiper-button-prev{
	background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ff0000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next{
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ff0000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination-bullet-active{
	background:#ff0000;
}

.swiper-pagination-bullet { width: 8px; height: 8px; }

.slider-desc { margin-bottom: 4*$lineheight; }

/*Swiper Catousel*/

.carousel {
	width: 100%;
	/*height: 420px;*/
	position:relative;
    .swiper-container-carousel {
        width: 100%;
        height: 100%;
	    .swiper-slide {
	    	width: 320px;
	        text-align: center;
	        display: -webkit-box;
	        display: -ms-flexbox;
	        display: -webkit-flex;
	        display: flex;
	        -webkit-box-pack: center;
	        -ms-flex-pack: center;
	        -webkit-justify-content: center;
	        justify-content: center;
	        -webkit-box-align: center;
	        -ms-flex-align: center;
	        -webkit-align-items: center;
	        align-items: center;
	        .product-tile {
	        	width:100%;
	        	text-align: left;
	        }
	    }

    }
    .swiper-button-prev{
    	left: 0;
    	margin-left: -5%;
	}
	.swiper-button-next{
		right: 0;
    	margin-right: -5%;
	}
}


/*Responsive*/

@media screen and (max-width: 1060px) {
	.container { width: 90%; }
}

@media screen and (min-width: 993px) {
	.hide-on-large-only { display: none; }
}

@media screen and (min-width: 601px) {
	.hide-on-med-and-up { display: none; }
}

@media screen and (max-width: 600px) {
	.hide-on-small-only { display: none; }
}

@media screen and (max-width: 1148px) {
	.carousel {
		.swiper-buttons { display: none; }
	}
}
