/* phone.css */

@import "var";

$topmargin: 49px; 

@media only screen and (max-width: $break-phone) {

.tile { 
	float: none; 
	box-sizing: border-box; 
}

.half, .third, .two-thirds, .one-third {
	width: 100%!important;
	&.tile { margin-left:0!important; margin-right:0!important; }
}

.cols2 { column-count: 1; -webkit-column-count: 1; -moz-column-count: 1; }
.cols3 { column-count: 1; -webkit-column-count: 1; -moz-column-count: 1; }
.cols4 { column-count: 1; -webkit-column-count: 1; -moz-column-count: 1; }

.social-btns a.socicon-btn {
	margin:0 1.6% 6px 0;
}

/*Header*/

#header {
	#logodiv{
		height: 48px;
		line-height: 48px;
		font-size: 24px;
		overflow: hidden;
		.holder{
			padding-top: 0;
			padding-bottom: 0;
			.logo { 
				width: 34px; 
				height:44px; 
				line-height: 14px; 
				font-size: 16px; 
				overflow: hidden; 
				border:none; 
				margin-right: 2%;
				padding-top:4px;
				word-wrap: break-word;
				img { width: 100%; }
			}
			h1{ 
				float: none; 
				text-align:center;
				line-height: 48px;
			}
		}
		.navbar-toggle { 
			display: block; 
			position: relative;
		    float: right;
		    background: none;
		    padding: 15px 10px;
		    border-bottom:none;
		    box-sizing:border-box;
		    max-height:48px;
		    overflow: hidden;
			.icon-bar {
			    display: block;
			    position: relative;
			    width: 22px;
			    height: 3px;
			    border-radius: 1.5px;
			    margin-bottom: 3px;
			    background-color: $maincolor;
			}
		}
	}
}

#topmenumob {
	position:fixed;
	right:0;
	left:0;
	top: 49px;
	max-height: none;
	bottom: 0;
	overflow:auto;
	font-size: 24px;
	border: none;
	ul.top-nav {
		li {
			a {
				padding: 16px 0;
				margin: 0 5%;
			}
			i.material-icons {
				right:5%;
				padding: 16px 10px;
			}
		}
	}
	#cartlangs { 
		#cart { 
			a {
				padding: 16px 0;
				margin: 0 5%;
			}
		}
		#l10n {
			right:5%;
			margin-right:5px;
			top:16px;
		}
	}
}

.pagemenumargin {
	margin-top: $topmargin; 
}

/*Slider*/

.slider {
	margin-bottom: $lineheight;
	.swiper-container-slider, .swiper-container-single {
		.swiper-slide {
			.desc {
				font-size: $smalltext;
				h1 { font-size: $mediumtext; }
				h2 { font-size: $mediumtext; }
				h3 { font-size: $normaltext; }
				p { font-size: $smalltext; }
			 }
			.call-to-action{
				font-size: $smalltext; 
				padding: 0.25em 0.5em;
			}
		}
	}
}

.carousel {
    .swiper-container-carousel {
	    .swiper-slide {
	    	width: 100%;
	    }
	    
    }
}

/*Product*/

.product {
	.left-col {
		width: 100%;
		float:none;
	}
	.right-col {
		width: 50%;
		float:left;
		box-sizing:border-box;
		&:nth-of-type(2n) { padding-right:1%; }
		&:nth-of-type(2n+1) { padding-left:1%; }
		.dif-circle{
			width: 6px;
			height: 6px;
			margin-right: 4px;
			margin-top: 2px;
		}
		&.price-col { width:50%; }
		&.setups {width:100%!important; float:left;} 
		.setup-links .setup-link {
			width:30px;
			height:30px;
			line-height: 30px;
			font-size: $mediumtext;
		}
	}
	#pricediv {
		.addtocart, .discount { 
			width:100%;
			margin-top: 0.5em;
		}	
	}
	.quote {
		font-size: $bigtext; 
		.quote-container {
			display:block;
			
		}
		.quote-text {
			width:100%;
			text-align:center;
		}
		.quote-img {
			max-width:30%;
			margin:0 auto 11px auto;
			img {
				width:100%;
			}
		}
	}
}


/*Shop*/

.pageMenu { 
	height: auto;
	margin-top: 6px;
	ul li {
		display:block;
		float:none;
		text-transform: uppercase;
		font-size: 22px;
		margin-right:0;
		padding:0;
		a {
			display:block;
			text-align:center;
			padding: 16px 0;
			margin:0;
			border-top: 1px solid transparent; 
			border-bottom: 1px solid transparent; 
			&:hover { 
				border-top: 1px solid $borderinput; 
				border-bottom: 1px solid $borderinput; 
			}
		}
	}
}

.shop-block {
	.shop-title{
		text-align:center;
	}
}

/*Cart*/

#page-cart{
	.product{
		h1, .total {
			font-size: 150%;
		}
	}
	.btn, .btn-dark {
		width:100%!important;
		padding: 12px 0!important;
	}
	.section-total{
		h1{
			font-size: 150%;
		}
		.total{
			font-size: 150%;
		}
	}
}

	
/*Footer*/

footer{
	text-align:center;
	h1, h2, h3, .tile {
		text-align:center;
	}
	.third.tile {
		margin-bottom:44px;
	}
	.footer-imgs { 
		justify-content: center; 
		-webkit-justify-content: center;
	}
}

}

@media only screen and (max-width: $break-phone-small) {

#cookie-consent{
	.consent-container {
		a.call-btn, button.call-btn, button, a.btn, a.btn-light, a.btn-dark-small {
			display:block;
			margin: 0.5em 5%;
			width: 90%;
		}
	}
}
	
}